<template>
  <div class="J-form-input-content" @mouseleave="inputOver" @mousemove="inputHover" @click="focusActive">
    <div class="J-form-input" :class="{ 'J-form-input-error': error || errors.length > 0 }">
      <input v-show="type != 'textarea'" :type="type" :title="errors.length > 0 ? errors[0].$message : null"
        :minlength="min" :maxlength="max" :required="required" :disabled="disabled"
        @input="changeModel" @keyup="inputHover" @change="inputHover" @click="inputHover"
        @keypress="inputHover" v-model="value" ref="value" />
      <textarea v-if="type == 'textarea'" :title="errors.length > 0 ? errors[0].$message : null" :minlength="min"
        :maxlength="max" :required="required" @input="changeModel" @keyup="inputHover"
        @change="inputHover" @click="inputHover" @keypress="inputHover" v-model="value" ref="value">
      </textarea>
    </div>
    <div class="J-form-input-label" :class="{ active: inputActive || active }">
      {{ placeholder }}
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    value: "",
    inputActive: false,
    inputType: "input",
  }),
  props: {
    placeholder: { required: false, default: "" },
    text: { required: false, default: "" },
    type: { required: false, default: "text" },
    required: { required: false, default: false },
    error: { required: false, default: false },
    errors: { required: false, default: [] },
    max: { required: false, default: 255 },
    min: { required: false, default: 0 },
    active: { required: false, default: false },
    disabled: { required: false, default: false },
  },
  mounted() {
    this.value = this.text;
    this.inputHover();
  },
  methods: {
    inputOver() {
      if (this.value === "") {
        this.inputActive = false;
      }
    },
    inputHover() {
      if (!this.active) {
        this.inputActive = true;
      }
    },
    focusActive() {
      this.$refs.value.focus();
    },
    changeModel($event){
      //console.log($event.target.value)
      this.$emit('input', $event.target.value)
      this.$emit('inputChange', $event.target.value)
    }
  },
  watch: {
    text: function (value) {
      this.value = value;
      //console.log(this.value);
      this.inputHover("");
    },
    errors: function (value) {
      console.log(value);
    },
    type: function (value) {
      this.inputType = value;
    },
  },
};
</script>
